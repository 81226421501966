import React from 'react';
import './Button.css';
import { Link } from 'react-router-dom';

const Button = ({ imp, text, link }) => {
  return (
    <Link
      className={`button ${imp === 'secondary' ? 'button__white' : ''}`}
      to={link}
    >
      {text}
    </Link>
  );
};

export default Button;
