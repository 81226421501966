import React, { useEffect } from "react";
// import Button from "../Button";
// import { Link } from 'react-router-dom';
// import { useStateValue } from '../../context/StateProvider';
import { useSelector } from "react-redux";
import { Waypoint } from "react-waypoint";

import {
  Wrapper,
  Background,
  ImageContainer,
  Image,
  TitleContainer,
  TextBackground,
  TextContainer,
  Title,
  QuoteWrapper,
  Quote,
  AppendixWrapper,
  AppendixText,
  Row,
  Author,
  Source,
  Text,
  // ItemLowerThird,
  // ItemButtons,
} from "./ItemElements";
import Aos from "aos";
import "aos/dist/aos.css";
import { selectToggle } from "../../redux/toggleSlice";
import "./Item.css";
import { selectLeftDrawer } from "../../redux/leftDrawerSlice";
// import { CgEnter } from "react-icons/cg";
const Item = ({
  id,
  // containerId,
  // link,
  title,
  quote,
  author,
  text,
  text2,
  source,
  // aos,
  imageUrl,
  imageUrlTwo,
  imageUrlThree,
  // leftBtnTxt,
  // leftBtnLink,
  // rightBtnTxt,
  // rightBtnLink,
  // twoButtons,
  // first,
  // noButtons,
  snapAlign,
  // showTableOfContents,
  enteredLink,
  exitedLink,
  noAnimation,
  reverse,
  sample,
  sampleChapter1,
  altContent,
  appendix,
}) => {
  const { sideBarOpen } = useSelector(selectToggle);
  const { leftDrawerOpen } = useSelector(selectLeftDrawer);
  useEffect(() => {
    Aos.init({ duration: 1500 });
    const aosAnimation = document.querySelectorAll(".aos");
    let observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          entry.target.classList.add("aos-animate");
        } else {
          entry.target.classList.remove("aos-animate");
        }
      });
    });

    aosAnimation.forEach((aosObject) => {
      observer.observe(aosObject);
    });
  }, []);

  return (
    <Wrapper id={id} key={id}>
      <Waypoint
        onEnter={({ previousPosition, currentPosition, event }) => {
          enteredLink(previousPosition, currentPosition, event);
        }}
        onLeave={(previousPosition, currentPosition, event) => {
          exitedLink(previousPosition, currentPosition, event);
        }}
      >
        {appendix ? (
          <div id={id} name={id}>
            <Background
              snapAlign={snapAlign}
              sideBarOpen={sideBarOpen || leftDrawerOpen}
              reverse={reverse}
            >
              <TitleContainer reverse={reverse}>
                <Title
                  className="aos"
                  data-aos={`${noAnimation ? "" : "fade-down"}`}
                >
                  {title}
                </Title>
              </TitleContainer>
              {altContent.map((block, i) => (
                <div style={{ marginTop: "60px" }} key={block.id}>
                  <AppendixWrapper>
                    {block.title && (
                      <TitleContainer>
                        <Title>{block.title}</Title>
                      </TitleContainer>
                    )}
                    <AppendixText>{block.html}</AppendixText>
                  </AppendixWrapper>

                  <ImageContainer>
                    <Image key={block.id} src={block.imgUrl} alt="" />
                  </ImageContainer>
                </div>
              ))}
            </Background>
          </div>
        ) : (
          <div id={id} name={id}>
            <Background
              snapAlign={snapAlign}
              sideBarOpen={sideBarOpen || leftDrawerOpen}
              reverse={reverse}
            >
              <TitleContainer reverse={reverse} sampleChapter1={sampleChapter1}>
                <Title
                  className="aos"
                  data-aos={`${noAnimation ? "" : "fade-down"}`}
                >
                  {title}
                </Title>
                {quote && (
                  <QuoteWrapper>
                    <Quote
                      className="aos"
                      data-aos={`${noAnimation ? "" : "fade-right"}`}
                    >
                      "{quote}"
                    </Quote>
                    <Row>
                      <Author
                        className="aos"
                        data-aos={`${noAnimation ? "" : "fade-left"}`}
                      >
                        {author}
                      </Author>
                      {source && (
                        <Source
                          className="aos"
                          data-aos={`${noAnimation ? "" : "fade-left"}`}
                        >
                          {source}
                        </Source>
                      )}
                    </Row>
                  </QuoteWrapper>
                )}
              </TitleContainer>
              <ImageContainer reverse={reverse}>
                <Image src={imageUrl} alt="" />
              </ImageContainer>
            </Background>
            <TextBackground
              reverse={reverse}
              sample={sample}
              snapAlign={snapAlign}
              sideBarOpen={sideBarOpen}
            >
              <TextContainer>
                <Text dangerouslySetInnerHTML={{ __html: text }}></Text>
                {text2 && (
                  <Text dangerouslySetInnerHTML={{ __html: text2 }}></Text>
                )}
              </TextContainer>
            </TextBackground>
            {imageUrlTwo && (
              <ImageContainer>
                <Image src={imageUrlTwo} alt="" />
              </ImageContainer>
            )}
            {imageUrlThree && (
              <ImageContainer>
                <Image src={imageUrlThree} alt="" />
              </ImageContainer>
            )}
          </div>
        )}
      </Waypoint>
    </Wrapper>
  );
};

export default Item;
