import React from "react";
import "./BookProposal.css";
import Item from "../../components/Item";
import { RiArrowUpSLine } from "react-icons/ri";
import { HashLink } from "react-router-hash-link";
import BookProposalNav from "../../components/BookProposalNav";
import { useSelector } from "react-redux";
import { selectToggle } from "../../redux/toggleSlice";
import Footer from "../../components/Footer";

const BookProposal = ({ data }) => {
  const { sideBarOpen } = useSelector(selectToggle);

  const enteredLink = (a, b, c) => {
    // console.log("enteredLink");
    // console.log(a);
    // console.log(b);
    // console.log(c);
  };

  const exitedLink = (a, b, c) => {
    // console.log("exitedLink");
    // console.log(a);
  };
  return (
    <>
      <BookProposalNav
        enteredLink={enteredLink}
        exitedLink={exitedLink}
        data={data}
      />
      <div>
        {data.bookProposal.map((image, i) => (
          <Item
            key={image.id}
            id={image.id}
            link={image.link}
            title={image.title}
            quote={image.quote}
            author={image.author}
            source={image.source}
            text={image.html}
            aos={image.aos}
            imageUrl={image.imageUrl}
            imageUrlTwo={image.imageUrlTwo}
            leftBtnTxt=""
            leftBtnLink=""
            rightBtnTxt=""
            rightBtnLink=""
            noButtons
            snapAlign="false"
            showTableOfContents="true"
            enteredLink={enteredLink}
            exitedLink={exitedLink}
            noAnimation={image.noAnimation}
          />
        ))}
        <div
          style={{
            height: "100vh",
            width: "100vw",
            backgroundColor: "black",
            display: "flex",
          }}
        >
          <iframe
            style={{
              margin: "auto",
              scrollSnapAlign: "center",
              overflow: "hidden",
              overflowX: "hidden",
              overflowY: "hidden",
              height: "100%",
              width: "100%",
              // position: 'absolute',
              top: "0px",
              left: "0px",
              right: "0px",
              bottom: "0px",
            }}
            height="100%"
            width="100%"
            src="https://www.youtube.com/embed/eumkYPY6S-Q/?autoplay=0&controls=1&mute=0&cc_load_policy=1&rel=0&modestbranding=1&autohide=1&showinfo=0&loop=0"
            title="Unifying America Video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameBorder="0"
          ></iframe>
        </div>
        <div
          style={{ height: "60px", width: "100vw", backgroundColor: "black" }}
        ></div>
        <div
          style={{
            height: "100vh",
            width: "100vw",
            backgroundColor: "black",
            display: "flex",
          }}
        >
          <iframe
            style={{
              margin: "auto",
              scrollSnapAlign: "center",
              overflow: "hidden",
              overflowX: "hidden",
              overflowY: "hidden",
              height: "100%",
              width: "90%",
              // position: 'absolute',
              top: "0px",
              left: "0px",
              right: "0px",
              bottom: "0px",
            }}
            width="100%"
            height="100%"
            src="https://kuula.co/share/collection/7F0TK?logo=1&info=1&fs=1&vr=0&zoom=1&thumbs=1"
            title="Overall book - Exterior"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
            frameBorder="0"
          ></iframe>
        </div>

        {data.chapters.map((chapter, i) => (
          <Item
            key={chapter.id}
            id={chapter.id}
            link={chapter.link}
            title={chapter.title}
            quote={chapter.quote}
            author={chapter.author}
            text={chapter.html}
            text2={chapter.html2}
            source={chapter.source}
            aos={chapter.aos}
            imageUrl={chapter.imageUrl}
            imageUrlTwo={chapter.imageUrlTwo}
            noButtons
            snapAlign="false"
            showTableOfContents="true"
            enteredLink={enteredLink}
            exitedLink={exitedLink}
            reverse={chapter.reverse}
            noAnimation={chapter.noAnimation}
            sample={chapter.sample}
            sampleChapter1={chapter.sampleChapter1}
          />
        ))}
        {/* <div className='bookProposal__button-wrapper'>
          <NavHashLink
            to='/unifying_america/book#chapter1'
            className='bookProposal__button'
          >
            Sample Chapters{' '}
            <RiArrowRightSLine className='bookProposal__arrow-right' />
          </NavHashLink>
        </div> */}
        <HashLink
          className={`${sideBarOpen ? "arrow-up-icon none" : "arrow-up-icon"}`}
          smooth
          to="#"
        >
          <RiArrowUpSLine />
        </HashLink>
      </div>
      <Footer />
    </>
  );
};

export default BookProposal;
