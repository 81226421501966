import React, { useState, useLayoutEffect } from 'react';
import './Query.css';
import Typing from 'react-typing-animation';
import { NavHashLink } from 'react-router-hash-link';
import { RiArrowRightSLine } from 'react-icons/ri';

const Query = () => {
  const [showText, setShowText] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const fadeIn = () => {
    setShowText(true);
    setShowButton(true);
    // setTimeout(() => {
    //   document.getElementById('candleVideo').play();
    //   setTimeout(() => {
    //     setShowImage(false);
    //   }, 9000);
    // }, 15000);
  };

  useLayoutEffect(() => {
    setShowImage(true);
  }, []);

  return (
    <section id='query' className='query'>
      {/* <div className='query__video-wrapper'>
        <video
          id='candleVideo'
          className='query__video'
          src={CandleVideo}
          muted
          type='video/mp4'
        ></video>
      </div> */}
      <div className={`query__image-wrapper ${showImage ? 'fade-in' : ''}`}>
        <img
          src='https://kevin-jain-website-bucket.s3.us-east-2.amazonaws.com/static/img/unifying_humanity/book_proposal/bp_cover.png'
          alt=''
        />
      </div>
      <div className='query__text-container'>
        <Typing speed={36} onFinishedTyping={() => fadeIn()}>
          <span className='italic'>
            "Any sufficiently advanced technology is indistinguishable from
            magic"
            <br />– Arthur Clarke, 3<sup>rd</sup> Law
          </span>
        </Typing>
        <>
          <p className={`paragraph ${showText ? 'show-1' : ''}`}>
            Rapid, radical transformation is nothing new, as humans have evolved
            and been innovating for millennia: the Cognitive Revolution 70,000
            years ago, the Agricultural Revolution 12,000 years ago, the
            Industrial Revolution 300 years ago, and the Digital Revolution 50
            years ago, have all led to the modern day, where humankind’s
            evolution is accelerating ever faster.
          </p>

          <p className={`paragraph ${showText ? 'show-2' : ''}`}>
            <span className='italic'>Unifying Humanity</span>, a 3-book series,
            lays out the next phase of human evolution: an even faster Spiritual
            Revolution. From the ashes of volcanic eruptions to the oceans of
            Pangaea, from the Fall of Man to the jungles of Africa, from the
            caves in Mesopotamia to the front side of the moon,{' '}
            <span className='italic'>Homo sapiens</span> has come a long way
            and, whether harnessing fire, inventing light-bulbs, or building
            skyscrapers, she has regularly achieved the impossible.
          </p>

          <p className={`paragraph ${showText ? 'show-3' : ''}`}>
            With the defining values of responsibility, unity, and peace,{' '}
            <span className='italic'>Unifying America</span>, Book 1 of the
            series, lays out the blueprints for eight tangible, large-scale
            social projects that lays out a vision to unite and give direction
            to a fragmented population.{' '}
          </p>
          <NavHashLink
            to='/unifying_america#overview'
            className={`query__button ${showButton ? 'show' : ''}`}
          >
            Book Proposal <RiArrowRightSLine className='query__arrow-right' />
          </NavHashLink>
        </>
      </div>
    </section>
  );
};

export default Query;
