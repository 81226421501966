import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import { useDispatch, useSelector } from 'react-redux';
import './Navbar.css';

import { Nav, NavbarContainer, NavMenu } from './NavbarElements';
import {
  scrollNavTrue,
  scrollNavFalse,
  selectNavigation,
} from '../../redux/navigationSlice';
import {
  stickyTocHide,
  stickyTocShow,
  selectStickyToc,
} from '../../redux/stickyTocSlice';
import { selectLeftDrawer } from '../../redux/leftDrawerSlice';
import { toggleSideBar, selectToggle } from '../../redux/toggleSlice';

const Navbar = ({ data }) => {
  const { navigation } = useSelector(selectNavigation);
  const { sideBarOpen } = useSelector(selectToggle);
  const dispatch = useDispatch();
  const { scrollNav } = navigation;
  const { stickyTocIsVisible } = useSelector(selectStickyToc);
  const { leftDrawerOpen } = useSelector(selectLeftDrawer);

  const changeNav = () => {
    if (scrollNav === true) {
      return;
    } else if (window.scrollY > 60) {
      dispatch(scrollNavTrue());
    } else if (window.scrollY <= 60) {
      dispatch(scrollNavFalse());
    }
  };
  const fadeInToc = () => {
    if (stickyTocIsVisible === true) {
      return;
    } else if (window.scrollY > 1275) {
      dispatch(stickyTocShow());
      // console.log('dispatch(stickyTocShow())');
    } else if (window.scrollY <= 1275) {
      dispatch(stickyTocHide());
      // console.log('dispatch(stickyTocHide())');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
    window.addEventListener('scroll', fadeInToc);
    return () => {
      window.removeEventListener('scroll', changeNav);
      window.removeEventListener('scroll', fadeInToc);
    };
  }, []);

  return (
    <>
      {/* <div className='nav__arrowIcon'>
        <RiArrowUpSLine />
      </div> */}
      <Nav scrollNav={scrollNav} leftDrawerOpen={leftDrawerOpen}>
        <NavbarContainer>
          <div
            className={`nav__icon ${sideBarOpen ? 'active' : ''}`}
            onClick={() => {
              dispatch(toggleSideBar());
            }}
          >
            <span className={`line-1 ${sideBarOpen ? 'active' : ''}`}></span>
            <span className={`line-2 ${sideBarOpen ? 'active' : ''}`}></span>
            <span className={`line-3 ${sideBarOpen ? 'active' : ''}`}></span>
          </div>

          <NavHashLink
            smooth
            to="/unifying_america#overview"
            exact
            className={`${sideBarOpen ? 'nav__logo hide' : 'nav__logo'}`}
          >
            Unifying America
          </NavHashLink>
          <NavMenu
            style={{
              opacity: sideBarOpen ? 0 : 1,
              transition: 'all 300ms ease-in-out',
            }}
          >
            {/* <NavLink to='/' exact activeClassName='current'>
              <li>Home</li>
            </NavLink> */}
            {/* <NavLink
              to="/unifying_america/query"
              exact
              activeClassName="current"
            >
              <li>Query</li>
            </NavLink> */}
            <NavLink
              to="/unifying_america/#overview"
              exact
              activeClassName="current"
            >
              <li>Book Proposal</li>
            </NavLink>
            {/* <NavLink
              to='/unifying_america/bookpage'
              exact
              activeClassName='current'
            >
              <li>BookPage</li>
            </NavLink> */}
            {/* <NavLink
              to="/unifying_america/book"
              exact
              activeClassName="current"
            >
              <li>Book</li>
            </NavLink> */}

            {/* <NavLink to='/ibench' exact activeClassName='current'>
              <li>iBENCH</li>
            </NavLink>

            <NavLink to='/india_to_go' exact activeClassName='current'>
              <li>India To Go</li>
            </NavLink> */}

            {/* <NavLink to="/jain_capital" exact activeClassName="current">
              <li>Jain Capital</li>
            </NavLink> */}
            <NavLink
              to="/unifying_america/author"
              exact
              activeClassName="current"
            >
              <li>Author</li>
            </NavLink>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
