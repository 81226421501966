import React from 'react';
import Kids from '../components/Kids';
import SecondaryNavbar from '../components/SecondaryNavbar';

const KidsPage = () => {
  return (
    <>
      <SecondaryNavbar />
      <Kids />
    </>
  );
};

export default KidsPage;
