import React from "react";
import Item from "../../components/Item";
import { RiArrowUpSLine, RiArrowRightSLine } from "react-icons/ri";
import { HashLink, NavHashLink } from "react-router-hash-link";
// import SecondaryNavbar from '../../components/SecondaryNavbar';
import "./Robots.css";

const Robots = ({ data }) => {
  const enteredLink = (a, b, c) => {
    // console.log('enteredLink');
    // console.log(a);
    // console.log(b);
    // console.log(c);
  };

  const exitedLink = (a, b, c) => {
    // console.log('exitedLink');
    // console.log(a);
  };
  return (
    <>
      {/* <SecondaryNavbar /> */}
      <div>
        {data.robots.map((image, i) => (
          <Item
            key={image.id}
            id={image.id}
            link={image.link}
            title={image.title}
            quote={image.quote}
            author={image.author}
            source={image.source}
            text={image.html}
            aos={image.aos}
            imageUrl={image.imageUrl}
            imageUrlTwo={image.imageUrlTwo}
            leftBtnTxt=""
            leftBtnLink=""
            rightBtnTxt=""
            rightBtnLink=""
            noButtons
            snapAlign="false"
            showTableOfContents="true"
            enteredLink={enteredLink}
            exitedLink={exitedLink}
            noAnimation={image.noAnimation}
          />
        ))}
        <div
          style={{
            height: "100vh",
            width: "100vw",
            backgroundColor: "black",
            display: "flex",
          }}
        >
          <iframe
            style={{
              margin: "auto",
              scrollSnapAlign: "center",
              overflow: "hidden",
              overflowX: "hidden",
              overflowY: "hidden",
              height: "100%",
              width: "100%",
              // position: 'absolute',
              top: "0px",
              left: "0px",
              right: "0px",
              bottom: "0px",
            }}
            height="100%"
            width="100%"
            src="https://www.youtube.com/embed/A-lM8gYLXG4/?autoplay=0&controls=1&mute=0&cc_load_policy=1&rel=0&modestbranding=1&autohide=1&showinfo=0&loop=0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>

        <div className="robots__button-wrapper">
          <NavHashLink
            to="/unifying_america/book#chapter1"
            className="robots__button"
          >
            Sample Chapters{" "}
            <RiArrowRightSLine className="robots__arrow-right" />
          </NavHashLink>
        </div>
        <HashLink className="arrow-up-icon" smooth to="#">
          <RiArrowUpSLine />
        </HashLink>
      </div>
    </>
  );
};

export default Robots;
