import React, { useRef, useState } from 'react';
import { Container, Form, Input, IconContainer, Button } from './LoginElements';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from '../../firebase';
import { auth } from '../../firebase';
import { FaEye } from 'react-icons/fa';
import { FaEyeSlash } from 'react-icons/fa';
import styles from './Login.module.css';

const Login = () => {
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const [hidePassword, setHidePassword] = useState(true);
  const toggleHidePassword = () => {
    setHidePassword(!hidePassword);
  };

  const backgroundImg =
    'https://kevin-jain-website-bucket.s3.us-east-2.amazonaws.com/static/img/unifying_humanity/book_proposal/bp_cover.png';
  // const register = (e) => {
  //   e.preventDefault();
  //   createUserWithEmailAndPassword(
  //     auth,
  //     emailRef.current.value,
  //     passwordRef.current.value
  //   ) // Use the imported function
  //     .then((userCredential) => {
  //       const user = userCredential.user;
  //       // console.log(user);
  //     })
  //     .catch((error) => {
  //       alert(error.message);
  //     });
  // };

  const signIn = (e) => {
    e.preventDefault();
    var userInput = emailRef.current.value;
    var modUserInput = userInput;
    const symbol = /[@]/g;
    let symbolCheck = userInput.match(symbol);
    if (!symbolCheck) {
      modUserInput = userInput.replace(/\s/g, '').concat('@kevinjain.com');
    }
    signInWithEmailAndPassword(auth, modUserInput, passwordRef.current.value) // Use the imported function
      .then((userCredential) => {
        const user = userCredential.user;
        console.log(user);
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  return (
    <Container
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), url(${backgroundImg})`,
      }}
    >
      <Form onSubmit={signIn}>
        <Input type="text" ref={emailRef} placeholder="Username or Email" />
        {hidePassword ? (
          <Input type="password" ref={passwordRef} placeholder="Password" />
        ) : (
          <Input type="text" ref={passwordRef} placeholder="Password" />
        )}

        <IconContainer onClick={() => toggleHidePassword()}>
          {hidePassword ? (
            <>
              <p className={styles.loginPasswordHint}>Unhide</p>
              <FaEyeSlash className={styles.loginIcon} />
            </>
          ) : (
            <>
              <p className={styles.loginPasswordHint}>Hide</p>
              <FaEye className={styles.loginIcon} />
            </>
          )}
        </IconContainer>
        <Button type="submit">Enter</Button>
        {/* <Button onClick={register}>Create</Button> */}
      </Form>
    </Container>
  );
};

export default Login;
