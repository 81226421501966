import React from "react";
import "./Footer.css";
import LinkedIn from "../../assets/img/LinkedIn.png";
const Footer = () => {
  return (
    <footer className="footer__background">
      <div className="footer__content">
        <a
          href="https://www.linkedin.com/in/kevinjain/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="footer__logo" src={LinkedIn} alt="" />
        </a>
        <p className="footer__text">© 2021 Kevin V Jain</p>
      </div>
    </footer>
  );
};

export default Footer;
