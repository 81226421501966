import React, { useState, useMemo } from "react";
import {
  Container,
  Menu,
  Links,
  MoreIcon,
  LeftIcon,
} from "./BookProposalNavElements";
import { useSelector } from "react-redux";
import { selectToggle } from "../../redux/toggleSlice";

const BookProposalNav = ({ data }) => {
  // const [links, setLinks] = useState([]);
  const { sideBarOpen } = useSelector(selectToggle);
  const [tocOpen, setTocOpen] = useState(false);

  const concatLinks = () => {
    let results = data.bookProposal.concat(data.chapters);
    return results;
  };
  const links = useMemo(() => concatLinks());

  const toggleTocMenu = () => {
    setTocOpen(!tocOpen);
  };

  const { scrollNav } = useSelector((state) => state.navigation);

  return (
    <Container
      $tocOpen={tocOpen}
      style={{
        opacity: sideBarOpen ? 0 : 1,
        transition: "all 300ms ease-in-out",
      }}
      scrollNav={scrollNav}
    >
      <MoreIcon $tocOpen={tocOpen} onClick={toggleTocMenu} />
      <LeftIcon $tocOpen={tocOpen} onClick={toggleTocMenu} />
      <Menu>
        {links.map((item, i) => (
          <Links
            to={`${item.link}`}
            spy={true}
            smooth={true}
            hashSpy={true}
            duration={500}
            // delay={100}
            isDynamic={true}
            key={item.link}
            activeClass="active"
            exact="true"
            offset={0}
            onClick={toggleTocMenu}
          >
            {item.linkTitle}
          </Links>
        ))}
      </Menu>
    </Container>
  );
};

export default BookProposalNav;
