import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  SecondaryNav,
  SecondaryNavbarContainer,
  SecondaryNavMenu,
} from './Elements';

const SecondaryNavbar = () => {
  const { scrollNav } = useSelector((state) => state.navigation);
  return (
    <>
      <SecondaryNav scrollNav={scrollNav}>
        <SecondaryNavbarContainer>
          <SecondaryNavMenu>
            <NavLink to='/unifying_america/book' activeClassName='current'>
              <li>Book</li>
            </NavLink>
            <NavLink
              to='/unifying_humanity/robots'
              exact
              activeClassName='current'
            >
              <li>Robots</li>
            </NavLink>
            <NavLink
              to='/unifying_humanity/kids'
              exact
              activeClassName='current'
            >
              <li>Kids</li>
            </NavLink>
          </SecondaryNavMenu>
        </SecondaryNavbarContainer>
      </SecondaryNav>
    </>
  );
};

export default SecondaryNavbar;
