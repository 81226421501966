import React, { useEffect } from 'react';
// import IMAGES_URL from '../../services/image.service';
import Aos from 'aos';
import 'aos/dist/aos.css';
import PictureBackground from '../../components/PictureBackground';
import './IndiaToGo.css';
const IndiaToGo = ({ data }) => {
  // const createIndices = (n, tag) => {
  //   let results = [];
  //   for (let i = 0; i <= n; i++) {
  //     results.push({
  //       index: i,
  //       id: tag + '-' + i,
  //     });
  //   }
  //   return results;
  // };

  // const images = createIndices(7, 'horizontal-comic');

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <>
      <div className='scroll-snap-y'>
        {/* <div style={{ height: '100vh', scrollSnapAlign: 'center' }}> */}
        <div
          style={{
            height: '100vh',
            width: '100vw',
            backgroundColor: 'black',
            display: 'flex',
          }}
        >
          <iframe
            style={{
              margin: 'auto',
              scrollSnapAlign: 'center',
              overflow: 'hidden',
              overflowX: 'hidden',
              overflowY: 'hidden',
              height: '100%',
              width: '100%',
              // position: 'absolute',
              top: '0px',
              left: '0px',
              right: '0px',
              bottom: '0px',
            }}
            height='100%'
            width='100%'
            src='https://www.youtube.com/embed/TyAfkeGmzrk/?autoplay=1&controls=0&mute=1&cc_load_policy=1&rel=0&showinfo=0&loop=1'
            title='YouTube video player'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          ></iframe>
        </div>

        {/* <PictureBackground
          id='FoodExit'
          title=''
          desc=''
          descLink=''
          backgroundImg={IMAGES_URL + 'india_to_go/misc/food_exit.jpg'}
          leftBtnTxt=''
          leftBtnLink=''
          rightBtnTxt=''
          rightBtnLink=''
          noButtons
        />
        <PictureBackground
          id='FoodExit2'
          title=''
          desc=''
          descLink=''
          backgroundImg={IMAGES_URL + 'india_to_go/misc/food_exit2.jpg'}
          leftBtnTxt=''
          leftBtnLink=''
          rightBtnTxt='LEARN MORE'
          rightBtnLink=''
          noButtons
        /> */}
        {data.indiaToGo.map((image, i) => (
          <PictureBackground
            key={image.id}
            id={image.id}
            title=''
            desc=''
            descLink=''
            backgroundImg={image.imgUrl}
            leftBtnTxt=''
            leftBtnLink=''
            rightBtnTxt='LEARN MORE'
            rightBtnLink=''
            noButtons
          />
        ))}
      </div>
    </>
  );
};

export default IndiaToGo;
