import React from 'react';
import KidsData from '../../data/KidsData';
import IMAGES_URL from '../../services/image.service';
import PictureBackground from '../PictureBackground';

const Kids = () => {
  const createIndices = (n, tag, data) => {
    let results = [];
    for (let i = 0; i <= n; i++) {
      results.push({
        index: i,
        id: tag + i,
        link: tag + ' ' + i,
        title: data[i].title,
        desc: data[i].desc,
      });
    }
    return results;
  };

  const images = createIndices(5, 'chapter', KidsData);
  return (
    <div className='kids-scroll-snap-y'>
      {images.map((image, i) => (
        <PictureBackground
          key={image.id}
          id={image.id}
          title={image.title}
          desc={image.desc}
          descLink=''
          backgroundImg={`${IMAGES_URL}unifying_humanity/kids_book/${image.index}.jpg`}
          leftBtnTxt=''
          leftBtnLink=''
          rightBtnTxt=''
          rightBtnLink=''
          noButtons
        />
      ))}
    </div>
  );
};

export default Kids;
