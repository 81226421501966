import React from 'react';
import Button from '../Button';
import { useSelector } from 'react-redux';
import {
  Background,
  Container,
  Title,
  Description,
  LowerThird,
  ButtonContainer,
  // Expand,
} from './PictureBackgroundElements';
import { selectToggle } from '../../redux/toggleSlice';

const PictureBackground = ({
  title,
  desc,
  // descLink,
  backgroundImg,
  leftBtnTxt,
  leftBtnLink,
  // rightBtnTxt,
  // rightBtnLink,
  // twoButtons,
  noButtons,
  buttonStyle,
}) => {
  const { sideBarOpen } = useSelector(selectToggle);
  return (
    <div>
      <Background
        style={{ backgroundImage: `url(${backgroundImg})` }}
        sideBarOpen={sideBarOpen}
      >
        <Container>
          <Title>{title}</Title>
          <Description>{desc}</Description>
          {noButtons ? null : (
            <LowerThird>
              <ButtonContainer>
                <Button
                  imp={buttonStyle}
                  text={leftBtnTxt}
                  link={leftBtnLink}
                />
              </ButtonContainer>
            </LowerThird>
          )}
        </Container>
      </Background>
    </div>
  );
};

export default PictureBackground;
