import React from "react";
import {
  LeftDrawerContainer,
  LeftDrawerWrapper,
  LeftDrawerMenu,
  LeftDrawerLink,
  MoreIcon,
  LeftIcon,
  // LeftDrawerSubLink,
  // LeftDrawerNavHashLink,
  // LeftDrawerBtnWrap,
  // LeftDrawerButton,
} from "./LeftDrawerElements";

import { useDispatch, useSelector } from "react-redux";
import {
  toggleLeftDrawer,
  selectLeftDrawer,
} from "../../redux/leftDrawerSlice";

const LeftDrawer = ({ data }) => {
  const { leftDrawerOpen } = useSelector(selectLeftDrawer);
  const dispatch = useDispatch();

  return (
    <div>
      <LeftDrawerContainer $isOpen={leftDrawerOpen}>
        <MoreIcon
          $isOpen={leftDrawerOpen}
          onClick={() => {
            dispatch(toggleLeftDrawer());
          }}
        />
        <LeftIcon
          $isOpen={leftDrawerOpen}
          onClick={() => {
            dispatch(toggleLeftDrawer());
          }}
        />
        <LeftDrawerWrapper>
          <LeftDrawerMenu>
            {data.jainCapital.map((item, i) => (
              <LeftDrawerLink
                onClick={() => {
                  dispatch(toggleLeftDrawer());
                }}
                key={item.link}
                activeClass="active"
                smooth={true}
                duration={500}
                spy={true}
                hashSpy={true}
                exact="true"
                offset={60}
                to={`${item.link}`}
              >
                {item.linkTitle}
              </LeftDrawerLink>
            ))}
          </LeftDrawerMenu>
        </LeftDrawerWrapper>
      </LeftDrawerContainer>
    </div>
  );
};

export default LeftDrawer;
