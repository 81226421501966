import React from 'react';
import {
  SidebarContainer,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  RightArrow,
  SidebarNavHashLink,
  SideBtnWrap,
  SidebarRoute,
} from './SidebarElements';
import { useDispatch, useSelector } from 'react-redux';
import { selectToggle, toggleSideBar } from '../../redux/toggleSlice';
import { auth } from '../../firebase';
// import { selectNavigation } from "../../redux/navigationSlice";

const Sidebar = () => {
  const { sideBarOpen } = useSelector(selectToggle);
  const dispatch = useDispatch();
  // const { navigation } = useSelector(selectNavigation);
  // const { scrollNav } = navigation;

  const signOutUser = () => {
    auth.signOut().then(() => {
      dispatch(toggleSideBar());
    });
  };

  return (
    <div>
      <SidebarContainer $isOpen={sideBarOpen}>
        <RightArrow
          onClick={() => {
            dispatch(toggleSideBar());
          }}
          // scrollNav={scrollNav}
          $isOpen={sideBarOpen}
        />
        <SidebarWrapper>
          <SidebarMenu>
            {/* <SidebarLink
              to='/'
              onClick={() => {
                dispatch(toggleSideBar());
              }}
            >
              Home
            </SidebarLink> */}

            <SidebarLink
              to="/unifying_america"
              onClick={() => {
                dispatch(toggleSideBar());
              }}
            >
              Unifying America
            </SidebarLink>
            <SidebarNavHashLink
              to="/unifying_america#overview"
              onClick={() => {
                dispatch(toggleSideBar());
              }}
            >
              Book Proposal
            </SidebarNavHashLink>
            {/* <SidebarSubLink
              to='/unifying_america/query'
              onClick={() => {
                dispatch(toggleSideBar());
              }}
            >
              Query
            </SidebarSubLink> */}
            {/* <SidebarSubLink
              to='/unifying_america/book'
              onClick={() => {
                dispatch(toggleSideBar());
              }}
            >
              Book
            </SidebarSubLink> */}

            {/* <SidebarSubLink
              to='/unifying_humanity/robots'
              onClick={() => {
                dispatch(toggleSideBar());
              }}
            >
              Robots
            </SidebarSubLink> */}
            {/* <SidebarSubLink
              to='/unifying_humanity/kids'
              onClick={() => {
                dispatch(toggleSideBar());
              }}
            >
              Kids
            </SidebarSubLink> */}
            {/* <SidebarLink
              to='/ibench'
              onClick={() => {
                dispatch(toggleSideBar());
              }}
            >
              iBENCH
            </SidebarLink>
            <SidebarLink
              to='/india_to_go'
              onClick={() => {
                dispatch(toggleSideBar());
              }}
            >
              India To Go
            </SidebarLink> */}
            {/* <SidebarNavHashLink
              to="/jain_capital"
              onClick={() => {
                dispatch(toggleSideBar());
              }}
            >
              Jain Capital
            </SidebarNavHashLink> */}
            <SidebarNavHashLink
              to="/unifying_america/author"
              onClick={() => {
                dispatch(toggleSideBar());
              }}
            >
              Author
            </SidebarNavHashLink>
          </SidebarMenu>
          <SideBtnWrap>
            <SidebarRoute onClick={() => signOutUser()}>Logout</SidebarRoute>
          </SideBtnWrap>
        </SidebarWrapper>
      </SidebarContainer>
    </div>
  );
};

export default Sidebar;
