const KidsData = [
  {
    title: '',
    desc: '',
  },
  {
    title: '',
    desc: '',
  },
  {
    title: '',
    desc: '',
  },
  {
    title: '',
    desc: '',
  },
  {
    title: '',
    desc: '',
  },
  {
    title: '',
    desc: '',
  },
];

export default KidsData;
