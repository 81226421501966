import React from "react";
import LeftDrawer from "../../components/LeftDrawer";
import Item from "../../components/Item";
import { RiArrowUpSLine } from "react-icons/ri";
import { HashLink } from "react-router-hash-link";
import { useSelector } from "react-redux";
// import { selectLeftDrawer } from "../../redux/leftDrawerSlice";
import { selectToggle } from "../../redux/toggleSlice";
import Footer from "../../components/Footer";

const JainCapital = ({ data }) => {
  // const { leftDrawerOpen } = useSelector(selectLeftDrawer);
  const { sideBarOpen } = useSelector(selectToggle);
  const enteredLink = (a, b, c) => {
    // console.log("enteredLink");
    // console.log(a);
    // console.log(b);
    // console.log(c);
  };

  const exitedLink = (a, b, c) => {
    // console.log("exitedLink");
    // console.log(a);
  };
  return (
    <>
      <LeftDrawer
        enteredLink={enteredLink}
        exitedLink={exitedLink}
        data={data}
      />
      <div>
        {data.jainCapital.map((el, i) => (
          <Item
            key={el.id}
            id={el.id}
            link={el.link}
            title={el.title}
            quote={el.quote}
            author={el.author}
            source={el.source}
            text={el.html}
            aos={el.aos}
            imageUrl={el.imageUrl}
            imageUrlTwo={el.imageUrlTwo}
            imageUrlThree={el.imageUrlThree}
            leftBtnTxt=""
            leftBtnLink=""
            rightBtnTxt=""
            rightBtnLink=""
            noButtons
            snapAlign="false"
            showTableOfContents="true"
            enteredLink={enteredLink}
            exitedLink={exitedLink}
            noAnimation={el.noAnimation}
            appendix={el.appendix}
            altContent={el.altContent}
          />
        ))}
        <HashLink
          className={`${sideBarOpen ? "arrow-up-icon none" : "arrow-up-icon"}`}
          smooth
          to="#"
        >
          <RiArrowUpSLine />
        </HashLink>
      </div>
      <Footer />
    </>
  );
};

export default JainCapital;
