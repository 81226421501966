import React from "react";
import "./LoadingScreen.css";

const LoadingScreen = () => {
  return (
    <div className="loadingScreen">
      <div className="loader">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      {/* <img src='https://www.yrendague.com.py/assets/img/loading.gif' alt='' /> */}
    </div>
  );
};

export default LoadingScreen;
