import React from "react";
import "./Author.css";
import { RiArrowUpSLine } from "react-icons/ri";
import { HashLink } from "react-router-hash-link";
import { useSelector } from "react-redux";
import { selectNavigation } from "../../redux/navigationSlice";
import { selectToggle } from "../../redux/toggleSlice";
import Footer from "../../components/Footer";

const Author = () => {
  const { navigation } = useSelector(selectNavigation);
  const { scrollNav } = navigation;
  const { sideBarOpen } = useSelector(selectToggle);
  const Headshot =
    "https://kevin-jain-website-bucket.s3.us-east-2.amazonaws.com/static/img/headshot.jpg";
  return (
    <div className={`${sideBarOpen ? "dimmed" : ""}`} id="author__wrapper">
      {/* <div className='author__about-container'></div> */}
      <div className="author__headshot-container">
        <img className="author__headshot" src={Headshot} alt="" />
      </div>
      <div className="author__content-container">
        {/* <p className='italic'>
          <small>Below is a selected poem by the author in 2011.</small>
        </p> */}
        <div style={{ marginTop: "60px" }}>
          <h3 className="author__about">
            To a Safe Landing
            <small className="author__italic"> by Kevin Jain</small>
          </h3>
        </div>
        <p>
          There was once a young man with hands but no direction
          <br /> No meaning, and No faith in the one resurrection
          <br /> No love, no parents in his empty cheering section
          <br /> He is A brilliant man in the wrong profession.
        </p>
        <p>
          He is born an engineer but feels destined a writer
          <br /> He feels empty, without the guts of a fighter
          <br /> He has no fire, and less spark than a spent lighter
          <br /> He has the life satisfaction of an insurance underwriter.
        </p>
        <p>
          Seeking purpose, he recalls childhood actions of
          <br /> Sneaking to the asylum and becoming enraptured
          <br /> lost in their amazing stories, he’s filled with compassion
          <br /> Time just stops when he’s there, he swears it happens
        </p>{" "}
        <p>
          Feeling invincible, like nobody could catch him
          <br /> He drifts to the underground left, the days hot fashion
          <br /> Illegal, He’s off to jail if the Feds trap him
          <br /> But he’s committed to improving serf’s rations
        </p>
        <p>
          But it was soon easy to see and to say
          <br /> That this youthful vow wasn’t true love, nay
          <br /> This isn’t the motivation that can build Rome in a Day
          <br />
          It cannot move mountains let alone stir the Milky Way
        </p>
        <p>
          {" "}
          This isn’t the life-defining mission
          <br /> that leads to Defying nihilism
          <br />
          and solipsism, By breaking out of prison,
          <br /> and saying that mathemeticians with their Syllogisms and
          symbolisms
          <br /> may have more than garbage with’em
          <br /> And the mission of terrorism
          <br /> and populism may have a meaningful vision
          <br /> It’s the air-conditioned recognition
          <br /> that Catholicism and optimism,
          <br /> Extradition and abolition
          <br /> should be given to every organism
          <br /> His was the weak passion of us today.
          <br /> Nothing to die for but plenty to say
        </p>
        <p>
          Its not the burn that makes you need something at any cost
          <br /> or die for liberty in the cold frost
          <br /> swear bloody vengeance on someone whose double crossed
          <br /> to find and recapture yourself when you are lost
        </p>
        <p>
          Passionate, committed, but foolish nevertheless
          <br /> Arrested with nothing on him like all the best
          <br /> He finds its much more likely to be depressed
          <br />
          Than wake men from even a mediocre rest
        </p>
        <p>
          {" "}
          One day turned to a week. A month, two, then three
          <br /> When eight go by, its so hopeless there’s no need for a sentry
          <br /> Rather than break him all at once, they got to his core slowly
          <br /> He learns it takes 8 months of licking to get to the heart of a
          tootsie pop candy
        </p>
        <p>
          One dark cold morning they were awoken
          <br /> Their fates had been decided, the King had spoken
          <br /> Usually a short exile, the trial was surely token
          <br />
          They may as well go back to bed, out here it was soakin
        </p>
        <p>
          But then they are brought in carriages to an icy street,
          <br /> to the cold-hearted city of St. Pete
          <br /> Concerned, they looked for comfort but find no seat
          <br /> They settle for the nervous company in the eyes they dared meet
          <br />
        </p>
        <p>
          He rose to look up and saw a priest greet him
          <br /> With thousands of spectators who really didn’t care to see him
          <br />
          But it seemed the government was the one to need him
          <br /> as a warning of things to come, like Hitlers name on a peace
          treaty
        </p>
        <p>
          It was barely ironic that the scaffolds were the one thing built well
          <br />
          Well that, the coffins and their prison cells
          <br /> They are led to march in two lines, in rough parallel
          <br /> Their lines don’t intersect, but their lives have met in hell
        </p>
        <p>
          And slowly like a black widow something creeps up on him
          <br /> Like a Freudian Patient, he began to feel somethin
          <br /> like a stinger it sinks in
          <br />
          And then like poison it hits him
        </p>
        <p>
          He is shocked and jumps like ECT
          <br /> He is cold like the blood of these meat eating
          <br /> PC beasts who feast on the weekly he
        </p>
        <p>
          He’s ssso cold inside
          <br /> he couldn’t have cried if he tried
          <br /> like a groom whose bride just died
          <br /> like an innocent whose complied
          <br /> with every reasonable guide and the government he’s defied
        </p>
        <p>
          With only its people in mind
          <br /> and was made to part with all his pride
          <br />
          mercy to the law of a one eyed jekyll and hyde
          <br /> the government’s justice, justice without being tried
        </p>
        <p>
          Fumbling for words which came so easy before
          <br />
          Emotions flood in and show reason the door
          <br /> He stares out and sees himself in the apathetic poor
          <br /> and for his life, he cant remember the score of the war
          <br /> Hell he can’t remember nothing no more
        </p>
        <p>
          He whispers “It’s not possible that they mean to execute us” No no
          <br /> and in his mind, that’s it and that’s all
          <br /> as the guard reads out the final call
        </p>
        <p className="author__italic">
          “all of the accused are guilty as charged
          <br /> of intending to overthrow the national order,
          <br /> and are therefore condemned to
          <br /> death before a firing squad”
        </p>
        <p>and thats when he’s had it.</p>
        <p>
          Dissociation. Pure and simple
          <br /> Denial, by the fistful
          <br /> Anger, ready like a missile
          <br /> These emotions clashing like a cymbal
        </p>
        <p>
          Then it was confusion, like if a wild bore
          <br /> who swore he keeps whores in his dressing drawers
          <br /> as he never wore anything on the dancefloor
          <br /> as I woke up to hear him snore on my trapdoor
          <br /> running away on all metaphors
        </p>
        <p>
          Then it was overwhelm
          <br /> like if that trap door opened a new future realm
          <br />
          where nerve cells, fat cells, and blood cells
          <br /> were replaced by solar cells, silicon gel, and alexander graham
          bells;
        </p>
        <p>
          ahh the future, filled with other things making us helpless now as
          well
          <br /> Then it was all going to hell
          <br /> humans have no place in this earth hotel,
          <br /> I just wanna yell, I am not an empty shell
          <br /> I could be the moon that starts the swell
        </p>
        <p>
          Staring straight at the sky line he feels the hate
          <br /> I am nott Pagan and didn’t take Satan’s bait,
          <br /> I had no Silver Plate, or grand real estate,
          <br />I didn’t desecrate for a low tax rate
        </p>
        <p>
          I worked late,
          <br /> creating a welfare state
          <br /> Sure I’m no Alexander the Great,
          <br /> But at least sedate me,
          <br /> open the gate for me
          <br /> don’t take my life away from me,
          <br /> I’m begging thee
        </p>
        <p>
          I didn’t do anything in my life to deserve this
          <br /> I didn’t do anything in my life at all, except on the surface
          <br /> I didn’t live at all! It seems ever since I could crawl
          <br /> I was Paralyzed, unable to find a purpose
        </p>
        <p>
          Nervous that you’d throw me in the furnace
          <br /> I’m Earnest and concerned in
          <br /> burnin’ in hell
          <br /> Like I’ve wasted time away in that prisoner’s cell
        </p>
        <p>
          that rebel’s motel whose clientelle dwell in pain
          <br /> going insane to try to ascertain
          <br /> if their campaigns were all done in vain
          <br /> whose only flicker of life is poked, prodded, and pained away
          <br /> until their passive like the Proles piled in the crowd here
          today
        </p>
        <p>
          And as the officer reads out the individual charges
          <br /> he looked at a golden church spire from where God watches
          <br /> with gleaming sunlight bouncing off of it, like he pictures
          Mars is
          <br /> It was a moment in time forever etched wherever his mind is
        </p>
        <p>
          And like a new day it dawned on him
          <br /> Unlike People, he didn’t withdraw from this
          <br /> Like a sage he understood a new truth
          <br /> Like a Virgin, this feeling was welcome, but new
        </p>
        <p>
          What dawned on him was this
          <br /> “If I do Not die, If I am Not killed,
        </p>
        <p>
          my life will suddenly seem endless, a whole eternity,
          <br /> each minute a century. I will take account of everything that
          passes
          <br /> —I will not waste a second of life again”
        </p>
        <p>
          He understands.
          <br /> He blames not the Gods or the Guards,
          <br /> neither Fate nor the State,
          <br /> He knows its his fault
          <br /> He can’t blame some assault
          <br /> and beg for salt
        </p>
        <p>
          to put in these wounds
          <br /> I wish I knew sooner!
          <br /> I wish there was a sign or some impetus, preferably one that
          rhymed
          <br /> to alert me, to avert this crisis
        </p>
        <p>
          The fault is mine,
          <br /> the fault line is my own lifeline,
          <br /> the length is my lifetime,
          <br /> Now is the right damned time!
        </p>
        <p>
          the earth quaked
          <br /> and now I’m a-wake.
          <br /> Go away! I didn’t make a life, there’s nothing to-take!
        </p>
        <p>
          I have not taken risks
          <br /> And taken the woman I love and kissed her
          <br /> Had time to miss her or give her a good frisk or
        </p>
        <p>
          Thank my parents by paying it forward
          <br /> Or write a book that brings us one step toward
          <br /> Nirvana. Or whatever I believe
          <br /> I never even really figured why the world seems
          <br /> As it is. But it is.
        </p>
        <p>
          I never built anything up from scratch
          <br /> I never drew a painting that could fill a room
          <br /> I never became an Indian man by catching
          <br /> a boa constrictor high on shamanic shrooms
          <br /> Excuses to not do all those things arose before
          <br /> sadness, not thinking of it, and boredom
        </p>
        <p>
          I never had an experience, let alone a life <br />
          Hell I never even had 2.5 kids and a wife
          <br /> If only I knew before I was dead!
          <br /> That life is a thing that one day ends
          <br /> It seems Life is like a potluck
          <br /> We were given life and we gotta give something in return
          <br /> Got to make a life worth living and bring that to urn
        </p>
        <p>
          His heart is racing faster than the two carefree doves above
          <br /> He is awoken by the profundity but moved by a rough shove
        </p>
        <p>
          As the Guards prod time along like the prisoners
          <br /> A priest walks in, looking sinister
          <br /> and gives them hooded shirts and last rites,
          <br /> hearing confessions as they turned white
        </p>
        <p>
          The first three to be shot had their hoods pulled over
          <br /> They were tied to a post and as they hung over
          <br /> they could only look down and wish it were over
          <br /> this roller coaster of life, they’re ready to roll over
        </p>
        <p>
          Watching in horror he stood in the front
          <br /> first in line, group 1, he could feel the brunt
        </p>
        <p>
          His thoughts were begging so loudly his neighbours could feel
          <br /> That this man’s prayers were the real deal
          <br /> Its a moment so tense it could break steel
          <br /> A moment so stressful heart disease was urgent and real
        </p>
        <p>
          It’s a moment so shaken, coming up was their last meal
          <br /> Brace yourself for the government’s approval seal
          <br /> Grab your heels and kneel
          <br /> pray for one last appeal
        </p>
        <p>
          Women and children close your eyes
          <br /> close your ears and close your minds
        </p>
        <p>
          and with that the soldiers raise their rifles
          <br /> no point in trying to stifle himself
        </p>
        <p>
          He’s begging so hard, Please God, I’ve learned
          <br /> Your passionate son has returned,
        </p>
        <p>
          I now understand how to live life
          <br /> I understand hereafter is the afterlife
        </p>
        <p>
          and in a moment so deliberate free will can’t be denied
          <br /> The soldiers stopped as a man jumped and cried out...
          <br /> As suddenly a carriage came cruising into the square
          <br /> A man emerged with an envelope held high in the air
          <br /> The czar had spared their lives, this was a rare
          <br /> an unbelievable end to the whole affair
        </p>
        <p>
          Later that morning he was told his new sentence
          <br /> Four years of hard labor in Siberia as a repentance
          <br /> to be followed by a brief bit in the army, a worthy penance
        </p>
        <p>
          He was to be a writer, he declared
          <br /> Shackles were put on- four years were harsh, but not in despair
          <br /> He was unable to write- more torturous than anything but he
          didn’t care
          <br /> H wrote in the army, tortured himself over each page and
          rhyming pair
        </p>
        <p>Life was his; and live he did He later writ and declared</p>
        <p>
          <span className="author__italic">
            To be a human being wherever
            <br /> among human beings, and remain one forever,
            <br /> no matter what misfortunes befall,
            <br /> to not become depressed, and not to falter
            <br /> this is what life is, herein lies its task.
          </span>
          <br />
          Life, a second chance, isn’t too much to ask.
        </p>
        <p>
          Some pitied him, but he was grateful
          <br /> He felt no bitterness and was never hateful
          <br /> He continued exploring subjects wide and vast,
          <br /> treating each moment as if it were his last
        </p>
        <p>
          I once found a treasure map to heaven
          <br /> I had never sailed across any sea, let alone seven
          <br /> But I became a man on a mission, and in a hurry
          <br />
          But the map simply led to my childhood home, where happiness was
          buried
        </p>
        <p>
          I was confused then, but now finally understand what it was giving
          <br />
          knowledge, that the entrance fee to heaven is one life worth living
        </p>
        <p>April 2011</p>
      </div>
      {scrollNav && (
        <HashLink className="author__arrow-up-icon" smooth to="#">
          <RiArrowUpSLine />
        </HashLink>
      )}
      <Footer />
    </div>
  );
};

export default Author;
